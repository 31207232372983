<template>
  
  <header>

    <div class="logo">
        <a href="/">
            <img src="@/assets/img/logo.png" alt="De Oude Stoffeerderij - Logo">
        </a>
    </div>

    <nav class="hidden-sm hidden-xs">
        <div id="big-nav-container">
            <div>
                <ul>
                    <router-link 
                        tag="li"
                        active-class="active"
                        v-for="page in pages" 
                        :to="page.path"
                        :key="page.name" exact>
                            <a>{{ page.name }}<img src="@/assets/img/icons/small-arrow-right.svg" alt=""></a>
                    </router-link>
                </ul>
                <router-link to="/reserveren" class="btn btn-primary btn-icon"><img src="@/assets/img/icons/calendar-check.svg" />Nu reserveren</router-link>
            </div>
        </div>
    </nav>

    <div class="mobile-nav visible-sm visible-xs">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <a href="#" class="pull-right btn btn-primary" @click.stop.prevent="toggleMenu">
                        <img src="@/assets/img/icons/menu.svg" alt="">
                    </a>
                    <a href="tel:+31416392186" class="btn btn-primary">
                        <img src="@/assets/img/icons/call.svg" alt="">
                    </a>
                </div>
            </div>
        </div>
        
    </div>

    <!--<div class="footer-icons visible-md visible-lg">
        <div class="icon-container">
            <a href="#" data-toggle="modal" data-target="#ContactModal">
                <i class="bi_com-email"></i>
            </a>
            <a href="https://www.facebook.com/MeubelstoffeerderijRiaPruissen/" target="_blank">
                <i class="bi_logo-facebook"></i>
            </a>
            <a href="https://nl.pinterest.com/Stofferderij/" target="_blank">
                <i class="bi_logo-pinterest"></i>
            </a>
        </div>
        
    </div>-->

  </header>

</template>

<script>
import { eventBus } from '../../main.js';

export default {
    computed: {
        pages() {
            return this.$router.options.routes.filter(function(route) {
                return route.name != null && route.name != "Reserveren" && route.name != "Algemene voorwaarden"
            })
        }
    },
    methods: {
        toggleMenu() {
            eventBus.$emit('toggle-menu');
        }
    }
}
</script>

<style>

</style>
