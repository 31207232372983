import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import App from './App.vue'
import { routes } from './routes';
import AppBackgroundImage from './components/modules/AppBackgroundImage.vue';
import AppMobileSidebar from './components/layout/AppMobileSidebar.vue'

Vue.component('background-image', AppBackgroundImage);

Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(Meta);

const router = new VueRouter({
  routes,
  mode: 'history'
});

export const eventBus = new Vue({
  data: {
    menuOpen: false
  },
  watch: {
    menuOpen(val) {
      document.body.className = val ? 'menu-is-open' : '';
    }
  }
});

new Vue({
  render: h => h(App),
  router,
  mounted() {
    this.$el.addEventListener('click', () => {
      eventBus.menuOpen = false;
    });
  }
}).$mount('#app')

new Vue({
  render: h => h(AppMobileSidebar),
  router
}).$mount('#mobile-sidebar')