<template>
    
    <div class="container-fluid">
        <div class="row image--row">
            <background-image v-for="(image, index) in images" :key="index" :image="image" :prefix="prefix" class="col-sm-4 image"></background-image>	
        </div>
    </div>

</template>

<script>
export default {
    props: ['images', 'prefix']
}
</script>

<style lang="scss" scoped>
    .image--row {
        .image {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            height: 400px;
        }
        .background-image-holder {
            position: relative;
            width: 33.33333333%;
        }
    }

    @media only screen and (max-width: 768px) {
        .image--row {
            .image {
                height: 300px;
            }
            .background-image-holder {
                width: 100%;
            }
        }
    }
</style>
