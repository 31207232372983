<template>
    <div class="background-image-holder" :style="backgroundPosition">
        <img alt="image" :src="'/static/img/' + prefix + image">
    </div>
</template>

<script>
export default {
    props: {
        image: {
            default: ''
        },
        prefix: {
            default: ''
        },
        position: {
            default: ''
        }
    },
    computed: {
        backgroundPosition() {
            return this.position != '' ? 'background-position: center ' + this.position + ' !important;' : '';
        }
    },
    mounted() {
        var background = this.$el.querySelector('img').src;
        this.$el.style.backgroundImage = "url('" + background +"')";
        this.$el.style.opacity = 1;
    },
    updated() {
        var background = this.$el.querySelector('img').src;
        this.$el.style.backgroundImage = "url('" + background +"')";
        this.$el.style.opacity = 1;
    }
}
</script>
