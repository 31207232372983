<template>
  <div id="app">

    <div id="wrapper">

      <app-header @navigate="navigate($event)"></app-header>

      <transition :name="animation" :mode="animationMode">
        <router-view></router-view>
      </transition>

    </div>

  </div>
</template>

<script>
import AppHeader from './components/layout/AppHeader.vue'
import { eventBus } from './main.js';

export default {
  name: 'app',
  metaInfo: {
    title: 'Pagina',
    titleTemplate: '%s | De Oude Stoffeerderij'
  },
  components: {
    AppHeader
  },
  data() {
    return {
      animation: ''
    }
  },
  computed: {
    animationMode() {
      return this.animation == 'fade' ? 'out-in' : '';
    }
  },
  watch: {
    '$route'(from, to) {
      if (!eventBus.loaded) return;

      var i_from = this.$router.options.routes.findIndex(x => x.name == from.name);
      var i_to = this.$router.options.routes.findIndex(x => x.name == to.name);

      if (window.innerWidth > 992) {
        this.animation = i_to > i_from ? 'slide-down' : 'slide-up';
      } else {
        this.animation = 'fade';
      }
      eventBus.menuOpen = false;
    }
  },
  mounted() {
    /* eslint-disable */
    $("body").on('click', 'a[href^="#"]', function(event) {

      if (this.hash !== "") {
        event.preventDefault();

        var hash = this.hash;
        var pos = $(this).data("position");
        var container = 'html,body';
        
        if (window.innerWidth > 992) {
          container = 'section';
        }

        $(container).animate({
          scrollTop: $(hash).offset().top
        }, (pos * 200), function(){

          window.location.hash = hash;
        });
      }
    });
    /* eslint-enable */
  }
}
</script>

<style lang="scss">
  @import "./assets/css/flickity.css";
  @import "./assets/scss/main";

  .slide-down-enter {
    transform: translateY(-100vh);
  }

  .slide-down-enter-active {
    transition: transform .7s ease-out; 
  }

  .slide-down-enter-to {
    transform: translateY(0);
  }

  .slide-down-leave {
    transform: translateY(0);
  }

  .slide-down-leave-active {
    transition: transform .7s ease-out; 
  }

  .slide-down-leave-to {
    transform: translateY(50vh);
  }

  .slide-up-enter {
    transform: translateY(100vh);
  }

  .slide-up-enter-active {
    transition: transform .7s ease-out; 
  }

  .slide-up-enter-to {
    transform: translateY(0);
  }

  .slide-up-leave {
    transform: translateY(0);
  }

  .slide-up-leave-active {
    transition: transform .7s ease-out; 
  }

  .slide-up-leave-to {
    transform: translateY(-50vh);
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 0.3s; 
  }

  .fade-leave-active {
    opacity: 0;
    transition: opacity 0.3s; 
  }
  
</style>
