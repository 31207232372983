import Home from './components/pages/Home.vue'
// import Accomodatie from './components/pages/Accomodatie.vue'
// import Omgeving from './components/pages/Omgeving.vue'
// import Arrangementen from './components/pages/Arrangementen.vue'
// import ArrangementenOverzicht from './components/pages/arrangementen/Overzicht.vue'
// import ArrangementKlein from './components/pages/arrangementen/KleineRondvaart.vue'
// import ArrangementGroot from './components/pages/arrangementen/GroteRondvaart.vue'
// import PrijzenEnBeschikbaarheid from './components/pages/PrijzenEnBeschikbaarheid.vue'
// import Gastenboek from './components/pages/Gastenboek.vue'
// import Contact from './components/pages/Contact.vue'
// import Reserveren from './components/pages/Reserveren.vue'

const Accomodatie = resolve => {
    require.ensure(['./components/pages/Accomodatie.vue'], () => {
        resolve(require('./components/pages/Accomodatie.vue'));
    });
};
const Omgeving = resolve => {
    require.ensure(['./components/pages/Omgeving.vue'], () => {
        resolve(require('./components/pages/Omgeving.vue'));
    });
};
const Arrangementen = resolve => {
    require.ensure(['./components/pages/Arrangementen.vue'], () => {
        resolve(require('./components/pages/Arrangementen.vue'));
    });
};
const PrijzenEnBeschikbaarheid = resolve => {
    require.ensure(['./components/pages/PrijzenEnBeschikbaarheid.vue'], () => {
        resolve(require('./components/pages/PrijzenEnBeschikbaarheid.vue'));
    });
};
const Gastenboek = resolve => {
    require.ensure(['./components/pages/Gastenboek.vue'], () => {
        resolve(require('./components/pages/Gastenboek.vue'));
    });
};
const Contact = resolve => {
    require.ensure(['./components/pages/Contact.vue'], () => {
        resolve(require('./components/pages/Contact.vue'));
    });
};
const Reserveren = resolve => {
    require.ensure(['./components/pages/Reserveren.vue'], () => {
        resolve(require('./components/pages/Reserveren.vue'));
    });
};
const AlgemeneVoorwaarden = resolve => {
    require.ensure(['./components/pages/AlgemeneVoorwaarden.vue'], () => {
        resolve(require('./components/pages/AlgemeneVoorwaarden.vue'));
    });
};

// Children
const ArrangementenOverzicht = resolve => {
    require.ensure(['./components/pages/arrangementen/Overzicht.vue'], () => {
        resolve(require('./components/pages/arrangementen/Overzicht.vue'));
    });
};
const ArrangementKlein = resolve => {
    require.ensure(['./components/pages/arrangementen/KleineRondvaart.vue'], () => {
        resolve(require('./components/pages/arrangementen/KleineRondvaart.vue'));
    });
};
const ArrangementGroot = resolve => {
    require.ensure(['./components/pages/arrangementen/GroteRondvaart.vue'], () => {
        resolve(require('./components/pages/arrangementen/GroteRondvaart.vue'));
    });
};
const WinterEfteling = resolve => {
    require.ensure(['./components/pages/arrangementen/WinterEfteling.vue'], () => {
        resolve(require('./components/pages/arrangementen/WinterEfteling.vue'));
    });
};

export const routes = [
    { path: '/', component: Home, name: 'Home' },
    { path: '/accomodatie', component: Accomodatie, name: 'Accomodatie' },
    { path: '/omgeving', component: Omgeving, name: 'Omgeving' },
    { path: '/arrangementen', component: Arrangementen, name: 'Arrangementen', 
    children: [
        { path: '/', component: ArrangementenOverzicht, name: 'Arrangementen' },
        { path: 'kleine-rondvaart', component: ArrangementKlein, name: 'Arrangementen' },
        { path: 'grote-rondvaart', component: ArrangementGroot, name: 'Arrangementen' },
        { path: 'winter-efteling', component: WinterEfteling, name: 'Arrangementen' }
    ] },
    { path: '/prijzen-en-beschikbaarheid', component: PrijzenEnBeschikbaarheid, name: 'Prijzen & beschikbaarheid' },
    { path: '/gastenboek', component: Gastenboek, name: 'Gastenboek' },
    { path: '/contact', component: Contact, name: 'Contact' },
    { path: '/reserveren', component: Reserveren, name: 'Reserveren' },
    { path: '/algemene-voorwaarden', component: AlgemeneVoorwaarden, name: 'Algemene voorwaarden' },
    { path: '*', redirect: '/' }
];