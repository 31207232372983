<template>
    
    <page page="home" theme="dark">
        <div class="banner-big">
            <div class="caption">
                <h2>Bed &amp; Breakfast</h2>
                <h1>De Oude Stoffeerderij</h1>
<!--                <div class="alert alert-danger" style="max-width: 750px;margin: 25px auto;">
                    <h4>Corona maatregelen B&amp;B De Oude Stoffeerderij</h4>
                    Om uw verblijf zo prettig en veilig mogelijk te houden, kunnen wij zorgen voor
                    een contactloos verblijf. Wij zullen alle Corona maatregelen in acht nemen zoals
                    geen handen schudden en 1,5 meter afstand te houden voor onze beider
                    gezondheid.<br/>
                    <br/>
                    Wij dragen zorg voor een goed gedesinfecteerd huisje.<br/><br/>
                    Bij uw aankomst zullen wij vóór de ingang tekst en uitleg geven van het huisje
                    en de serre.<br/><br/>
                    Tijdens uw verblijf zullen wij het huisje alléén voor noodzakelijk onderhoud
                    betreden.<br/><br/>
                    Het ontbijt wordt bereid met alle benodigde hygiëne maatregelen Het ontbijt
                    wordt de volgende ochtend op de afgesproken tijd buiten bij de ingang op de
                    tafel gezet. Als jullie klaar zijn, mogen jullie het weer bij de ingang op de tafel
                    zetten, zodat wij het weer mee kunnen nemen.<br/><br/>
                    Als jullie nadien nog vragen mochten hebben, neem dan altijd telefonisch of per
                    app contact op met Evert 0(31)6-57947863 of Ria 0(31)6-22388927
                </div>-->
                <div class="btn--bar">
                    <span><router-link to="/accomodatie" class="btn btn-primary btn-icon"><img src="@/assets/img/icons/home.svg" />Onze accomodatie</router-link></span>
                    <span><router-link to="/reserveren" class="btn btn-primary btn-icon"><img src="@/assets/img/icons/calendar-check.svg" />Nu reserveren</router-link></span>
                </div>
            </div>
            <a href="#intro" class="read-more" data-position="4">
                <img src="@/assets/img/icons/chevron-down.svg" alt="">
            </a>
        </div>

        <div id="intro" class="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <h3>Welkom bij B&amp;B De Oude Stoffeerderij</h3>
                        <p class="normal">
                            Op een erf waar rust en activiteit zich prima mengen bevindt zich B&amp;B de Oude Stoffeerderij . De B&amp;B is gesitueerd in een vrijstaande woning met een afgesloten binnentuin en serre. 
                            Hij staat achter op het terrein tegen een wei aan. Met uitzicht op een boomgaard. Op het erf kunt u Meubelstoffeerderij Ria Pruissen vinden met een bedrijfspand. 
                            We hebben een dierenwei met kippen, die zorgen voor de verse eitjes en twee zwarte hangbuikzwijntjes ‘Spare en Rib’.
                            Daarnaast loopt onze hond ‘Noa’ rustig rond op het erf.
                        </p>
                        <p>
                            De achtergelegen wei is van een hobby boer. Zij hebben recht van overpad over het erf. Dagelijks verzorgen zij de pony’s die op de wei lopen.
                        </p>
                        <p>
                            Voor de deur van de B&amp;B bevindt zich een sloot, waar de kikkers in het seizoen welig tieren en u heeft uitzicht over een fraaie boomgaard met sjilpende vogels. U zult een oase aan rust ervaren.
                        </p>
                    </div>
                    <div class="col-lg-4">
                        <aside class="sidebar">
                            <div class="row">
                                <div class="col-lg-12 col-md-6">
                                    <div class="module">
                                        <ul class="checklist">
                                            <li>Gastvrijheid</li>
                                            <li>Sfeervol &amp; Knus</li>
                                            <li>Rustzoekers</li>
                                            <li>Natuurliefhebbers</li>
                                            <li>Diervriendelijk</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-6">
                                    <!--<div class="module image">
                                        <h3>Winter Efteling</h3>
                                        <p>
                                            Gratis kaarten bij een boeking vanaf 7 nachten.
                                        </p>
                                        <router-link to="/arrangementen/winter-efteling" class="btn btn-primary">Nu bekijken</router-link>
                                    </div> -->
                                    
                                    <div class="module image">
                                        <h3>Arrangementen</h3>
                                        <p>
                                            Lekker genieten van een rondvaart in de Biesbosch?
                                        </p>
                                        <router-link to="/arrangementen" class="btn btn-primary">Nu bekijken</router-link>
                                    </div>
                                    
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>

        <image-row :images="['voorkant.jpg', 'varkens.jpg', 'serre.jpg']" prefix="accomodatie/small/"></image-row>

    </page>

</template>

<script>
import Page from '../modules/AppPage.vue';
import ImageRow from '../modules/AppImageRow.vue';

export default {
    metaInfo: {
        title: 'De Oude Stoffeerderij | Bed & Breakfast',
        titleTemplate: null,
        meta: [
            { vmid: 'description', name: 'description', content: 'Op een erf waar rust en activiteit zich prima mengen bevindt zich B&B de Oude Stoffeerderij . De B&B is gesitueerd in een vrijstaande woning met een afgesloten tuin en serre.' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.deoudestoffeerderij.nl'}
        ]
    },
    components: {
        Page,
        ImageRow
    }
}
</script>
