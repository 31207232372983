<template>
    <nav id="mobile-sidebar" class="text-center">
        <h3>Menu</h3>
        <ul>
            <router-link 
                tag="li"
                active-class="active"
                v-for="page in pages" 
                :to="page.path"
                :key="page.name" exact>
                    <a>{{ page.name }}</a>
            </router-link>
        </ul>
        <router-link to="/reserveren" class="btn btn-primary btn-icon"><img src="@/assets/img/icons/calendar-check.svg" />Nu reserveren</router-link>
    </nav>
</template>

<script>
import { eventBus } from '../../main.js';

export default {
    computed: {
        pages() {
            return this.$router.options.routes.filter(function(route) {
                return route.name != null && route.name != "Reserveren"
            })
        }
    },
    created() {
        eventBus.$on('toggle-menu', () => {
            eventBus.menuOpen = !eventBus.menuOpen;
        });
    }
}
</script>