<template>
    <div id="main">

        <section :id="page" :class="theme">

            <div class="banner" v-if="type == 'page'">
                <div class="caption">
                    <div class="container">
                        <h1>{{ $route.name }}</h1>
                    </div>
                </div>
            </div>

            <slot></slot>

            <footer>
                <div class="row">
                    <div class="col-sm-6">
                    <div class="links">
                        <a href="/algemene-voorwaarden" class="block">Algemene voorwaarden</a>
                        <span class="space">|</span>  
                        <a href="/privacy-verklaring" target="_blank">Privacy verklaring</a>
                    </div>
                    </div>
                    <div class="col-sm-6 text-right">
                        <a href="https://www.upresent.nl/websites">Webdesign</a> door <a href="https://www.upresent.nl">U Present</a>
                    </div>
                </div>
            </footer>

        </section>

    </div>
</template>

<script>
import { eventBus } from '../../main.js';
export default {
    props: ['page', 'theme', 'type'],
    mounted() {
        eventBus.loaded = true;
    }
}
</script>
